<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VMoney } from "v-money";

export default {
  locales: {
    en: {},
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  directives: { money: VMoney },
  data() {
    return {
      total: {
        contracts: 0,
        balance: 0,
      },

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },

      loading: {
        check: false,
        send: false,
      },

      contract: {
        progress: 1,

        period: 0,
        value: '',

        date: {
          start: '',
          end: '',
        }
      }
    };
  },
  methods: {
    getTotalInvestiments() {
      api
        .get('stakes/contracts/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.contracts = response.data.total.contracts
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = response.data.balance
          }
        })
    },
    checkContract() {
      this.loading.check = true;

      api
        .post('stakes/contracts/check', {
          value: this.contract.value
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.contract.period = response.data.period;
            this.contract.date.start = response.data.date.start;
            this.contract.date.end = response.data.date.end;

            this.contract.progress = 2;
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .finally(() => {
          this.loading.check = false;
        });
    },
    sendContract() {
      this.loading.check = true;

      api
        .post('stakes/contracts', {
          value: this.contract.value
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.contract.progress = 2;

            this.$router.push("/stakes/" + response.data.contract.id);
            this.$toast.success('Nova stake criada com sucesso.');
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .finally(() => {
          this.loading.check = false;
        });
    },
  },
  mounted() {
    this.getTotalInvestiments();
    this.getTotalBalance();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/stakes">Minhas Stakes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Nova Stake</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Nova Stake</h3>
        <p>Para comprar uma nova stake, siga os passos abaixo.</p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-8">
        <b-progress :value="contract.progress" max="3" class="mb-3" height="20px" variant="default"></b-progress>

        <template v-if="contract.progress == 1">
          <div class="card">
            <div class="card-body">
              <p class="text-center">Você tem um total de <strong>{{ total.contracts | currency }}</strong> em stakes.</p>
              <hr>
              <div class="d-flex justify-content-between p-3">
                <div>Compra mínima</div>
                <div>R$ 500,00 </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="p-3">
                <b-form-group id="value" :label="t('Digite o valor da stake')" label-for="value">
                  <b-input-group>
                    <b-form-input id="value" v-model="contract.value" v-money="money" type="text" inputmode="decimal" size="lg"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="default" v-on:click="contract.value = $options.filters.currency(total.balance)">Usar total</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <p class="font-size-13 py-2">Saldo disponível: {{ total.balance | currency }}</p>
                </b-form-group>
                <div class="mt-4">
                  <b-button v-on:click="checkContract()" :disabled="loading.check == true || contract.value === 'R$ 0,00'" type="submit" variant="default" size="lg">
                    {{ t("Continuar") }}
                    <b-spinner v-if="loading.check" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="contract.progress == 2">
          <div class="card">
            <div class="card-header rounded-top">
              Compra da sua nova stake
            </div>
            <div class="card-body">
              <div class="bg-soft-warning p-3 rounded mb-3">
                Atenção! Para concluir a compra da sua nova stake, pedimos que confira e revise os dados abaixo e clique em 'Finalizar'.
              </div>
              <div class="card border-0 p-0">
                <div class="card-body p-0">
                  <div class="card-header rounded">
                    Detalhes da stake
                  </div>
                  <div class="card-body">
                    <strong>Valor:</strong> {{ contract.value }}<br>
                    <strong>Duração:</strong> {{ contract.period }} dias<br>
                    <strong>Data de Início:</strong> {{ contract.date.start }}<br>
                    <strong>Data de Término:</strong> {{ contract.date.end }}
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <b-button class="mr-3" v-on:click="contract.progress = 1;" type="submit" variant="light" size="lg">
                  {{ t("Voltar") }}
                </b-button>
                <b-button v-on:click="sendContract()" :disabled="loading.check == true || contract.value === 'R$ 0,00'" type="submit" variant="default" size="lg">
                  {{ t("Continuar") }}
                  <b-spinner v-if="loading.check" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="contract.progress == 3">
          <div class="card">
            <div class="card-body text-center py-5">
              <svg class="mb-2" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="m2.394 13.742 4.743 3.62 7.616-8.704-1.506-1.316-6.384 7.296-3.257-2.486zm19.359-5.084-1.506-1.316-6.369 7.279-.753-.602-1.25 1.562 2.247 1.798z"></path>
              </svg>
              <h4 class="mb-4">Nova stake criada com sucesso.</h4>
              <router-link class="btn btn-default btn-lg" tag="a" to="/stakes">
                Ver Stake
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<style>
.custom-control-label {
  padding-left: 20px;
  cursor: pointer;
}

.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: 0.08375rem;
  left: -1.9rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: 0.135rem;
  left: -1.856rem;
  width: 2rem;
  height: 2rem;
  background: no-repeat 50% / 50% 50%;
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  line-height: 37px;
}
</style>